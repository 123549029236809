<template>
  <div class='profile-page'>
    <div class='profile-cont'>
      <div class='profile-form'>
        <div class='profile-header'>
          <h2 class='header-label'>Profile</h2>
          <theme-button class="theme-toggle-cont" />
        </div>
        <hr>
        <div>
          <div class='input-row just-cont-fs' v-bind:class="{'flex-direction-column': this.$store.getters.getIsMobile}"
          >
            <div>
              <input id='first_name' placeholder='first name' />
              <label class='d-flex' for='first_name'>first name</label>
            </div>
            <div>
              <input id='last_name' placeholder='last name' />
              <label class='d-flex' for='last_name'>last name</label>
            </div>
          </div>
          <div class='input-row just-cont-fs' v-bind:class="{'flex-direction-column': this.$store.getters.getIsMobile}">
            <div>
              <input id='username' placeholder='username' />
              <label class='d-flex' for='username'>username</label>
            </div>
            <div>
              <input id='email' placeholder='email' />
              <label class='d-flex' for='email'>email</label>
            </div>
          </div>
          <div class='d-flex' v-bind:class="{'justify-center': this.$store.getters.getIsMobile}">
            <button class="btn btn-update" @click='updateUserInfo'>Update</button>
          </div>
        </div>
      </div>
      <div class='profile-form'>
        <h2 class='header-label'>Password</h2>
        <hr>
        <div>
          <div class='input-row just-cont-fs' v-bind:class="{'flex-direction-column': this.$store.getters.getIsMobile}">
            <div>
              <input id='current_password' type='password' placeholder='current password' v-model='current_password'/>
              <label class='d-flex' for='current_password'>current password</label>
            </div>
          </div>
          <div class='input-row just-cont-fs' v-bind:class="{'flex-direction-column': this.$store.getters.getIsMobile}">
            <div>
              <input id='new_password' type='password' placeholder='new password' v-model='new_password'/>
              <label class='d-flex' for='new_password'>new password</label>
            </div>
            <div>
              <input id='confirm_password' type='password' placeholder='confirm password' v-model='confirm_password'/>
              <label class='d-flex' for='confirm_password'>confirm password</label>
            </div>
          </div>
        </div>
        <div class='d-flex' v-bind:class="{'justify-center': this.$store.getters.getIsMobile}">
          <button class="btn btn-update" @click='updatePassword'>Update</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ThemeButton from "@/components/ThemeButton.vue";
import {request} from '@/config/axios';

export default  {
  data() {
    return {
      user: {},
      current_password: '',
      new_password: '',
      confirm_password: '',
    };
  },
  async mounted() {
    await this.setUser();
  },
  components: {
    ThemeButton,
  },
  methods: {
    setUser() {
      this.user = JSON.parse(localStorage.getItem('user')) ?? {};
      document.getElementById('first_name').value = this.user.first_name;
      document.getElementById('last_name').value = this.user.last_name;
      document.getElementById('username').value = this.user.username;
      document.getElementById('email').value = this.user.email;
    },
    async updateUserInfo() {
      const data = await this.getData();
      if (Object.keys(data).length === 0) {
        this.$toast.add({
          severity: "error",
          summary: "Not Changed!",
          detail: "Information not changed",
          life: 3000,
        });

        return;
      }
      await request
        .put('user', data)
        .then(async () => {
          await this.getUser();
        })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Profile Updated",
            life: 3000,
          });
        })
        .catch((error) => {
          console.log(error)
        });
    },
    async getData() {
      const fields = ['first_name', 'last_name', 'username', 'email'];
      const data = {};
      for (let i = 0; i < fields.length; i++) {
        if (document.getElementById(fields[i]).value !== this.user?.[fields[i].toString()]) {
          data[fields[i].toString()] = document.getElementById(fields[i]).value;
        }
      }

      return data;
    },
    async getUser() {
      await request
        .get("user")
        .then(async (response) => {
          localStorage.setItem('user', JSON.stringify(response.data));
          await this.$store.dispatch("updateUser", response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    async updatePassword() {
      const current_password = document.getElementById('current_password').value;
      const new_password = document.getElementById('new_password').value;
      const confirm_password = document.getElementById('confirm_password').value;

      const data = {
        'current_password': current_password,
        'new_password': new_password,
        'confirm_password': confirm_password
      };

      if (!current_password || !new_password || !confirm_password) {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: "Password fields are empty",
          life: 3000,
        });
        return;
      }

      if (new_password.length < 8 || confirm_password.length < 8) {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: "Password must be at least 8 characters",
          life: 3000,
        });
        return;
      }

      await request
        .put('auth/password', data)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Password Updated",
            life: 3000,
          });
        })
        .then(() => {
          this.current_password = '';
          this.new_password = '';
          this.confirm_password = '';
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Failed",
            detail: "Failed to update password",
            life: 3000,
          });
        });
    },
  },
};
</script>
<style>
.profile-page {
  display: flex;
  justify-content: center;
}

.profile-header {
  display: flex;
  justify-content: space-between;
}

.theme-toggle-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 25px;
}

.profile-cont {
  width: 85%;
  overflow: auto;
  height: 730px;
  left: 118px;
  top: 178px;

  background: var(--background-color-secondary);
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.25);
}

.profile-cont hr {
  border: 0.25px solid var(--accent-color);
  width: 95%;
}

.input-row {
  display: flex;
  align-items: center;
  padding: 15px;
}

.just-cont-fs {
  justify-content: flex-start;
}

.header-label {
  display: flex;
  font-weight: 100;
  margin-left: 25px;
}

.btn:hover {
  color: #00A4FF;
  background-color: var(--background-color-primary);
  border: 1px solid #00A4FF;
  cursor: pointer;
}

.btn:active {
  border: 1px solid #00A4FF;
  background-color: #00A4FF;
  color: white;
}

.btn-update {
  width: 240px;
  height: 39px;
  top: 610px;
  margin: 10px 25px;
  font-size: 18px;
}
</style>